/* eslint-disable no-console */
import React, { useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import * as validators from '../../util/validators';
import { Button, FieldReviewRating, FieldTextInput, ReviewRating } from '../../components';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import css from './ReviewForm.module.css';

const formName = 'addasdsadsadsd';

//Using Star Rating

const FormComponent = props => (
  <FinalForm
    {...props}
    formId={formName}
    render={fieldRenderProps => {
      const { formId, handleSubmit, onChange, invalid, pristine, submitting,currentListing ,reviews} = fieldRenderProps;
      const [showForm, setShowForm] = useState(false);
      const required = validators.required('This field is required');
      const submitDisabled = invalid || pristine || submitting;

      const handleShowForm = ()=>{
        setShowForm(!showForm);
      }

      const title = currentListing?.attributes?.title;

      return (


        <>

        <div onClick={handleShowForm} className={css.subHeader}>
          <h5>Reviews</h5>
          <span>+</span>
        </div>
       

        {showForm?
          <>
           {reviews.length>0?"":
              <>
                <h5 class='my-3'>REVIEWS</h5>
                <p className={css.margin1}>There are no reviews yet.</p>
              </>
            }
            {reviews.map((review,key)=>{

                const {content,rating} = review.attributes;

                return(
                  <div key={key}>
                    <ReviewRating
                    className={css.resize}
                      id={`${formId}.rate1`}
                      name="rating"
                      rating={parseInt(rating)}
                      label="Your rating*:"
                      validate={required}
                    />

                    <p>
                      {content}
                    </p>
                  </div>
                )

              })}
          </>
        :""}
        
        
                  

              {
                    //          <form
                    //   onSubmit={handleSubmit}
                    // >
                    //   {
                    //     showForm?<>
                            
                    //           <h4 className={css.title}>{title}</h4>
                            
                    //           <FieldReviewRating
                    //             id={`${formId}.rate1`}
                    //             name="rating"
                    //             label="Your rating*:"
                    //             validate={required}
                    //           />

                    //           <FieldTextInput
                    //             className={css.field}
                    //             type="textarea"
                    //             id={`${formId}.yourReview`}
                    //             name="yourReview"
                    //             label="Your review"
                    //             validate={required}
                    //             rows="8"
                    //             />

                    //           <Button className={css.submitBtn2} style={{ marginTop: 24 }} type="submit" disabled={submitDisabled}>
                    //             SUBMIT
                    //           </Button>
                    //     </>
                    //     :""
                    //   }
                    
                    // </form>
              }
       
        </>
        
      );
    }}
  />
);

// export const StarRating = {
//   component: FormComponent,
//   props: {
//     onChange: formState => {
//       if (formState.dirty) {
//         console.log('form values changed to:', formState.values);
//       }
//     },
//     onSubmit: values => {
//       console.log('submit values:', values);
//     },
//   },
//   group: 'inputs',
// };


const ReviewForm = compose(injectIntl)(FormComponent);
ReviewForm.displayName = 'LoginForm';

export default ReviewForm;
