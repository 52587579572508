import React, { useEffect, useRef, useState } from 'react';
import css from './CustomListingCardSmall.module.css';

import classNames from 'classnames';
import f1 from '../../assets/banner1.png';
import NamedLink from '../NamedLink/NamedLink';
import compare from '../../assets/compare.PNG';
import heart from '../../assets/heart.png';
import mag from '../../assets/magnifying_glass.png';
import { createSlug } from '../../util/urlHelpers';
import { Button } from '..';



const CustomListingCardComponentSmall = props =>{
    const activeClassName = 'my-active-class';
    const {showSideForm,handleHideForm,data,img,handleAddToCart,handleAddToWishlist,createWishlistInProgress,wishlistId} = props;
    
    


    const title = data?.attributes?.title;
    const description = data?.attributes?.description;
    const price = data?.attributes?.price?.amount;
    let id  = data?.id?.uuid;
    const isWishListItem = wishlistId != undefined && wishlistId.includes(id)?true:false;
    if(id === null || id === undefined){
        id="abcuyuuyu";
    }

    if(title === null || title === undefined){return ""}

    //Load image in background---------
    // const myStyle = {
    //     backgroundImage: `url(${
    //         img
    //     })`,
       
    //     fontSize: "50px",
    //     backgroundSize: "contain",
    //     backgroundRepeat: "no-repeat",
    // };

  return (
    
    <>
         <div className={css.rowItem}>
                                    <div className={classNames(css.rowItemSub,css.roundEdge)}>
                                        
                                        <div>
                                        <NamedLink 
                                            name="ListingPage"
                                            className={css.loginLink}
                                            params={{ id: id, slug: createSlug({title}) }}
                                        >
                                            <img className={css.resize} src={img} />
                                        </NamedLink>
                                            
                                        </div>
                                        <div className={css.addToCart}>
                                            <Button
                                                className={css.loginLink}
                                                onClick={event=>handleAddToCart(event,id,title,description,price,1,img)}
                                            >
                                                ADD TO CART 
                                            </Button>
                                            
                                        </div>
                                        <div className={css.addSide}>
                                            <NamedLink name="LoginPage" className={css.loginLink}>
                                                <img className={css.resizeIcon} src={compare} />
                                            </NamedLink>
                                            <NamedLink name="LoginPage" className={css.loginLink}>
                                                <img  src={mag} />
                                            </NamedLink>
                                            <Button inProgress={createWishlistInProgress}  onClick={event=>handleAddToWishlist(event,id,title,description,price,1,img)} className={css.fav}>

                                                {isWishListItem?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
                                                    </svg>
                                                    :
                                                    <img  src={heart} />
                                                }
                                            </Button>
                                        </div>
                                       
                                    </div>
                                    <div className={css.caption}>
                                        <NamedLink 
                                            name="ListingPage"
                                            className={css.loginLink}
                                            params={{ id: id, slug: createSlug({title}) }}
                                        >
                                            <p className={classNames(css.centerItem,css.shortText)}><b>{description}</b></p>
                                            <p className={classNames(css.centerTitile,css.shortText)}>{title}</p>
                                        </NamedLink>
                                        
                                        <p>${price}</p>
                                        <Button 
                                            
                                            className={css.quickView}
                                        >
                                            QUICK VIEW
                                        </Button>
                                       
                                    </div>
                                </div>
    </>
    
  );
};


export default CustomListingCardComponentSmall;
