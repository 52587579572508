import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { isBookingProcess, resolveLatestProcessName } from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';


import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  Button,
  FieldTextInput,
  ExternalLink,
  ReviewRating,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  createCart,
  createWishlist,
} from './ListingPage.duck';


import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;
import f1 from '../../assets/brand4.jpeg';
import Slideshow from '../../components/CustomSection/SlideShow.js';
import compare from '../../assets/compare.PNG';
import heart from '../../assets/heart.png';
import ReviewForm, { StarRating } from '../../components/CustomSection/ReviewForm.js';


import CustomListingCardComponent from '../../components/CustomSection/CustomListingCard.js';
import CustomListingCardComponentSmall from '../../components/CustomSection/CustomListingCardSmall.js';



export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  
  const [showInfo, setShowInfo] = useState(false);
  const [showMoreProducts, setShowMoreProducts] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState("");

  

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    entities,
    onCreateCart,
    cartData,
    addToWishlist,
    createWishlistInProgress,
    wishlistId
  } = props;

  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias !== undefined?publicData?.transactionProcessAlias:"default-purchase/release-1";
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const processType = isBooking ? 'booking' : 'purchase';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && !currentUser?.attributes?.stripeConnected;
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  const storeName = currentAuthor?.attributes?.profile?.publicData?.storeName;
  const storeImage = currentAuthor?.attributes?.profile?.publicData?.storeImage?.url;
  const categories = currentListing.attributes.publicData.category;
  const rating = currentListing?.attributes?.metadata?.rating;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const onSubmitReview = (values)=>{
    console.log(values);
  };

  const handleOrderSubmit = values => {
    console.log("ooooooouuuuuuuuuuuooo--------------------");
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    console.log("ooooooouuuuppppppppppppppppppppuuuuuuuooo");
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      values={quantity:1,
        deliveryMethod: deliveryOption
      };
      console.log("oooooooooo");
      console.log(values);

      localStorage.setItem("CurrentAction", "BuyNow");
      console.log(localStorage.getItem("CurrentAction"));
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  //console.log("oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo");

  const imgUrl = currentListing?.images[0]?.attributes?.variants["my-variant"]?.url;
  const imageBtns = [];
  const slideImages = [];
  
  ///const imageBtns = [imgUrl, imgUrl, imgUrl];
  //const slideImages = [imgUrl, imgUrl, imgUrl];

  currentListing.images.map((image,key)=>{
    if(imageBtns.length < 5){
      imageBtns.push(image?.attributes?.variants["listing-card"]?.url);
      slideImages.push(image?.attributes?.variants["my-variant"]?.url);
    }
    
    
  });


  const {listing,image} = entities;
  
  
  const handleShowVendorInfo = ()=>{
    setShowInfo(!showInfo);
  }

  const handleShowMoreProducts = ()=>{
    setShowMoreProducts(!showMoreProducts);
  }

  const history_ = useHistory();
  const handleBuyNow = ()=>{
    history_.push("/s?pub_role=Sellers");
    //https://garageit-toflb3.mysharetribe-test.com/l/dewalt-20v-max-xr-brushless-4-tool-combo-kit-dck482d1m1/6568ef29-e8b0-4228-9714-a1320af168d1
  }

  const shopName = currentListing?.attributes?.publicData?.shopName;
  const shopUrl ="/store/"+ currentListing?.attributes?.publicData?.shopUrl;
  const shippingEnabled = currentListing?.attributes?.publicData?.shippingEnabled;
  const pickupEnabled = currentListing?.attributes?.publicData?.pickupEnabled;

  const handleAddToCart = (event,listingId,title,description,price,quantity,img)=>{

    if(!isOwnListing){
      //Check if exist
          const exist = cartData !== undefined && cartData.filter(item=> item.id === listingId);
          if(exist && exist.length !== 0){
            //handleHideShoppingCart();
            return;
          };
          
          const listingItem = {
                                id:listingId,
                                img:img,
                                title:title,
                                desc:description,
                                price:price,
                                quantity:quantity
                              };
          
          const newData = [listingItem]
          
          //setCartData(newData);
          onCreateCart(newData);
          //handleHideShoppingCart();
    }
   
  }

  const handleAddToWishlist = (event,listingId,title,description,price,quantity,img)=>{
    const listingItem = {
                          id:listingId,
                          img:img,
                          title:title,
                          desc:description,
                          price:price,
                          quantity:quantity
                        };
    
    const newData = [listingItem]
    
    //setCartData(newData);
    addToWishlist(newData);
    
  }

  const capitalizeFirstLetter = (str)=> {
    return str[0].toUpperCase() + str.slice(1);
  }

  const handleOption = (val)=>{
    setDeliveryOption(val.target.value)
  }



  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
     
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        
          <div className={css.mainColumnForProductLayout}>
           
           
          <Slideshow slideImages={slideImages} imageBtns={imageBtns}/>



          <div className={css.itemDetails}>
            <div className={css.breadcrumb}>
              <NamedLink className={css.noMarginLeft} name="LandingPage">Home</NamedLink>/<NamedLink name="SearchPage">Costume</NamedLink>/<span className={css.currentPage}>Girls</span>
            </div>
            <h2 className={css.title}>{title}</h2>
            <h4>${(price.amount/100).toFixed(2)}</h4>
            <p className={css.desc}>{description}</p>

            {shippingEnabled || pickupEnabled?
              <select className={css.selectOptn} onChange={handleOption}>
                <option>Select Delivery option</option>
                {pickupEnabled?<option value="pickup">Pickup</option>:""}
                {shippingEnabled?<option value="shipping">Shipping</option>:""}
              </select>:""
            }
            
            <div className={css.flexRowNoBorder}>
             
              <Button
                    className={css.submitBtn2}
                onClick={event=>handleAddToCart(event,listingId.uuid,title,description,price.amount,1,slideImages[0])}
              >

                  ADD TO CART 
              </Button>

              <Button className={css.submitBtn2} type="button" onClick={handleOrderSubmit}>
                BUY NOW
              </Button>
            </div>
           

            <div className={css.linkRow}>
              <NamedLink name="LoginPage" className={css.loginLink}>
                  <img className={css.resizeIcon} src={compare} />
                  Compare
              </NamedLink>
                                           
              
              <Button
                    className={css.loginLink}
                onClick={event=>handleAddToWishlist(event,listingId.uuid,title,description,price.amount,1,slideImages[0])}
              >
                <img  src={heart} />
                Add to wishlist
              </Button>
            </div>

            <hr/>

            <ReviewForm onSubmit={onSubmitReview} currentListing={currentListing} reviews={reviews}/>
           
                <div className={css.alignLeft}>
                  
                  <div  className={css.subHeader} onClick={handleShowVendorInfo}>
                    <h5>Vendor Info</h5>
                    <span>+</span>
                  </div>

                  {showInfo?
                  <>
                    <h5 class='my-3'>VENDOR INFORMATION</h5>
                    
                      <p>Store Name:<span className={css.mag5}>{shopName}</span></p>
                      <p>Vendor:  <ExternalLink href={shopUrl}><span className={css.mag5}>{authorDisplayName}</span> </ExternalLink></p>
                      <p>No ratings found yet!</p>
                    
                  </>

                  :
                ""
                }
                  
                  
                </div>
              
            

            <div>

              <div  className={css.subHeader} onClick={handleShowMoreProducts}>
                <h5>More products</h5>
                <span>+</span>
              </div>

              {showMoreProducts?
                <>
                <h5 class='my-3'>MORE PRODUCTS</h5>
              
                <div className={css.flexRowSmall}>
                      { listing !== undefined? Object.values(listing).map((data,key)=>{ 
                          if(key>2)return;
                          const id  = data?.id?.uuid;
                          const imgId = listing[id]?.relationships?.images?.data[0]?.id?.uuid;
                          const img = image[imgId]?.attributes?.variants['my-variant']?.url;

                          return(
                              <CustomListingCardComponentSmall 
                                data={data} 
                                img={img} 
                                handleAddToWishlist={handleAddToWishlist} 
                                createWishlistInProgress={createWishlistInProgress}
                                wishlistId={wishlistId}
                                handleAddToCart={handleAddToCart}
                              />
                          )
                          
                      }):""}
                </div>
                </>:""
              }
              
            </div>
           
            <div>
                Category: {categories !== undefined && categories.map((val,key)=>{return capitalizeFirstLetter(val) +", "})}
            </div>
            <div className={css.flexRow}>
              <img className={css.circleImg} src={storeImage}/>
              <div>
                <h4>{storeName}</h4>
                <form>
                    <ReviewRating
                      className={css.resize}
                      id={`rererererererere`}
                      name="rating"
                      rating={parseInt(rating)}
                      label="Your rating*:"
                    />
                </form>
              </div>
            </div>
          </div>

        </div>

        <div class={css.relatedProduct} >
          <h3>Related Products</h3>
            <center class={css.mainBorder}>
                <div id="carouselExampleRide2"  class="carousel slide bg-white" data-bs-ride="carousel">
                    <div class="carousel-inner mainBorder">
                        <div class="carousel-item active ">
                          <div className={css.flexRowNoBorder2}>
                                { listing !== undefined? Object.values(listing).map((data,key)=>{ 
                                    if(key>3)return;
                                    const id  = data?.id?.uuid;
                                    const imgId = listing[id]?.relationships?.images?.data[0]?.id?.uuid;
                                    const img = image[imgId]?.attributes?.variants['my-variant']?.url;

                                    return(
                                        <CustomListingCardComponent data={data} img={img} />
                                    )
                                    
                                }):""}
                          </div>
                           
                        </div>

                        <div class="carousel-item active ">
                          <div className={css.flexRowNoBorder2}>
                                { listing !== undefined? Object.values(listing).map((data,key)=>{ 
                                    if(key<4)return;
                                    if(key>7)return;
                                    const id  = data?.id?.uuid;
                                    const imgId = listing[id]?.relationships?.images?.data[0]?.id?.uuid;
                                    const img = image[imgId]?.attributes?.variants['my-variant']?.url;
                                    return(
                                        <CustomListingCardComponent data={data} img={img} />
                                    ) 
                                }):""}
                          </div>
                           
                        </div>
                       
                        
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide2" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon color_dark" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide2" data-bs-slide="next">
                        <span class="carousel-control-next-icon color_dark" aria-hidden="true"></span>
                        <span class="visually-hidden">
                            Next
                        </span>
                    </button>
                </div>
            </center>

        </div>

      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const {entities} = state.marketplaceData;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };





  let {
    cartData,
    createWishlistInProgress,
    createWishlistError,

  } = state.ListingPage;
  const wishlist = currentUser?.attributes?.profile?.privateData?.wishlist;
  const wishlistId = [];

  if(wishlist !== undefined && wishlist.length !== 0){
    wishlist.map((val,key)=>{
      wishlistId.push(val.id);
    });
  }
    
  const currentUserCart = currentUser?.attributes?.profile?.privateData?.cartData;
  cartData = cartData.length !== 0?cartData:currentUserCart;
  let quantity = 0;
  let prices = 0;

  if(cartData !== undefined && cartData.length !== 0 ){
    cartData.filter((item)=>{
    quantity += parseInt(item.quantity);
    prices += parseInt(item.price);
  });
  }





  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    entities,
    cartData,
    quantity,
    prices,
    wishlistId,
    createWishlistInProgress,
    createWishlistError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onCreateCart: (cartData) =>dispatch(createCart(cartData)),
  addToWishlist: (data) =>dispatch(createWishlist(data)),
  onSendReview: (data) =>dispatch(createWishlist(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);


export default ListingPage;
