import React, { useEffect, useRef, useState } from "react";
import f1 from '../../assets/banner1.png';
import css from './SlideShow.module.css';
import { getFormattedTotalPrice } from "../../containers/CheckoutPage/CheckoutPageTransactionHelpers";
import classNames from "classnames";


const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const images = [f1, f1, f1];
const delay = 250000;

const Slideshow =(props) =>{

    const {imgUrl,slideImages,imageBtns} = props;
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = ()=> {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {resetTimeout();};
  }, [index]);

  return (
          <>    
          <div className={css.slideMainCon}>
            <div className={css.slideImageBtnsCon}>
                
                {imageBtns.map((_, idx) => {

                    let classess = "";
                    if(index === idx){
                        classess = css.active;
                    }
                    return <img key={idx} className={classNames(css.slideImageBtn,classess)} src={imageBtns[idx]} 
                        onClick={() => {
                            setIndex(idx);
                        }}
                    />
                    
                    
                   
                    })}


                
            </div>
            <div className={css.slideShowCon}>
                        <div className={css.slideshow}>
                            <div className={css.slideshowSlider} style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                                {slideImages.map((img, index) => (
                                <div
                                    className={css.slide}
                                    key={index}
                                    
                                >
                                    <img className={css.imgs} src={slideImages[index]}/>
                                </div>
                                ))}
                            </div>
                            
                        
                        </div>
                        <div className={css.dots_con}>
                                <div className={css.slideshowDots}>
                                {imageBtns.map((_, idx) => {

                                    let classess = "";
                                    if(index === idx){
                                        classess = css.active;
                                    }
                                return <div key={idx} className={classNames(css.slideshowDot,classess)} 
                                            onClick={() => {
                                            setIndex(idx);
                                            }}
                                            ></div>
                                })}
                                </div>
                        </div>
                    </div>
                
          </div>
           

    

            </>
  );
}

export default Slideshow;

/**
 * 
 * 
 * <Slideshow
            imgUrl={imgUrl}
            />
 * 
 */